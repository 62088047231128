<template>
  <RsModal
    class="model-gallery__wrapper"
    @dismiss="closeModal"
  >
    <div class="modal-gallery__content">
      <IcCloseBlack class="ml-auto cursor-pointer" width="24" @click="closeModal"/>
      <slot
        name="collections"
        :collections="collections"
        :go-next="goNext"
        :go-previous="goPrevious"
      >
        <button
          v-if="hasCollections"
          class="button-nav left-2"
          type="button"
          @click="goPrevious"
        >
          <IcArrowLeft class="inline-block" width="7" height="12" />
        </button>
        <button
          v-if="hasCollections"
          class="button-nav right-2"
          type="button"
          @click="goNext"
        >
          <IcArrowRight class="inline-block" width="7" height="12" />
        </button>

        <div class="flex-1 flex flex-col">
          <div
            v-if="isImage"
            :key="currentIndex"
            class="model-gallery__image"
            :style="{ 'background-image': `url('${urlMedia}')` }"
            @touchstart="startDrag"
            @touchend="endDrag"
          />
          <div>
            <TutorialImageCaption
              v-if="caption"
              :content="caption"
            />
            <div v-if="hasCollections" class="flex items-center justify-center my-4 md:mb-0">
              <div
                v-for="(img, index) in collections"
                :key="index"
                class="button-page"
                :class="{ 'is-current': index === currentIndex }"
                @click="goToIndex(index)"
              />
            </div>
          </div>
        </div>
      </slot>
    </div>
  </RsModal>
</template>
 
<script setup lang="ts">
import { IcArrowLeft, IcArrowRight, IcCloseBlack } from '~/components/common/icons'
import { type Media, MediaType } from '~/models'
import TutorialImageCaption from '~/components/tutorialBlocks/TutorialImageCaption.vue'

const props = withDefaults(defineProps<{
  selectedImage: Media,
  collections: Media[],
}>(), {
  selectedImage: () => {},
  collections: () => [],
})

const emit = defineEmits(['commit'])
const closeModal = () => emit('commit')

const { selectedImage, collections } = toRefs(props)
const currentIndex = ref(collections.value.indexOf(selectedImage.value))
const touchstartX = ref(0)
const touchstartY = ref(0)
const touchendX = ref(0)
const touchendY = ref(0)

const hasCollections = computed(() => collections.value && collections.value.length > 1)

const attachment = computed(() => collections.value[currentIndex.value].attachment)
const caption = computed(() => collections.value[currentIndex.value].caption)
const urlMedia = computed(() => attachment.value.url)
const isImage = computed(() => attachment.value.media_type === MediaType.image)

const goToIndex = (index) => {
  let absoluteIndex = index % collections.value.length
  absoluteIndex =
    absoluteIndex < 0 ? absoluteIndex + collections.value.length : absoluteIndex
  currentIndex.value = absoluteIndex
}
const goNext = () => {
  goToIndex(currentIndex.value + 1)
}
const goPrevious = () =>{
  goToIndex(currentIndex.value - 1)
}
const startDrag = (event)=> {
  touchstartX.value = event.changedTouches[0].screenX
  touchstartY.value = event.changedTouches[0].screenY
}
const endDrag = (event) => {
  touchendX.value = event.changedTouches[0].screenX
  touchendY.value = event.changedTouches[0].screenY
  handleGesture()
}

const handleGesture = () => {
  if (touchendX.value < touchstartX.value) {
    goNext()
  }
  if (touchendX.value > touchstartX.value) {
    goPrevious()
  }
}
</script>
<style scoped>
.model-gallery__wrapper :deep(.modal__window) {
  @apply max-h-[480px];
}
.model-gallery__wrapper :deep(.modal__body) {
  @apply max-h-full;
}
.model-gallery__wrapper {
  @apply !p-4 items-center;
}
.modal-gallery__header {
  @apply py-4;
}
.modal-gallery__content {
  @apply w-full h-full flex flex-col;
}
.model-gallery__image {
  @apply flex-1 w-full;
  @apply bg-contain bg-center bg-no-repeat bg-gray-100;
}
.button-nav {
  @apply absolute -mt-3 z-10 w-8 h-8 bg-white rounded-full;
  top: 50%;
  filter: drop-shadow(0px 0px 10px rgba(37, 40, 43, 0.12));
}
.button-nav:hover {
  @apply opacity-80;
}
.button-page {
  @apply rounded-full mx-1 w-3 h-3 cursor-pointer bg-gray-200;
}
.button-page.is-current {
  @apply bg-rs-primary;
}
@screen sm {
  .model-gallery__wrapper :deep(.modal__window) {
    @apply !h-full !max-w-5xl max-h-[1024px];
  }
}
</style>
